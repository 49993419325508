<template>
  <div>
    <div id="ticketingMap" />
    <v-dialog
      v-model="ticketing.viewTicketingDetailPopup"
      :fullscreen="mobileBreakpoints()"
      width="1600"
    >
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <v-card>
        <v-card-title
          class="ticketing-card-title justify-center ma-0"
          color="black"
        >
          <div class="d-flex flex-grow-1 justify-space-between mt-5 mr-5">
            <v-spacer />
            <p>{{ $t('TicketingPopup.title') }}</p>
            <v-spacer />
            <v-icon
              class="text-right mr-n8 mt-n16"
              color="red"
              size="28"
              @click="ticketing.viewTicketingDetailPopup = false"
            >
              mdi-close
            </v-icon>
          </div>
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-row class="mt-4">
              <v-col
                cols="12"
                md="4"
                class="pt-0"
              >
                <v-text-field
                  v-model="request.request_count"
                  :label="$t('TicketingTable.requestNumber')"
                  :disabled="true"
                  type="text"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
                class="pt-0"
              >
                <v-text-field
                  v-model="selectedDocumentTypeNameTranslated"
                  rows="1"
                  :disabled="true"
                  auto-grow
                  :label="$t('Ticketing.documentType')"
                  type="text"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
                class="pt-0"
              >
                <v-text-field
                  v-model="requestMode"
                  :label="$t('TicketingTable.requestType')"
                  :disabled="true"
                  type="text"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model="requestDate"
                  :label="$t('TicketingTable.requestDate')"
                  :disabled="true"
                  type="text"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="evasionDate"
                  :label="$t('TicketingTable.evasionDate')"
                  :disabled="true"
                  type="text"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="evasionDateLimit"
                  :label="$t('TicketingTable.evasionLimit')"
                  :disabled="true"
                  type="text"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="translatedState"
                  :label="$t('TicketingTable.requestState')"
                  :disabled="true"
                  type="text"
                />
              </v-col>
              <v-col cols="6">
                <v-textarea
                  v-model="request.notes"
                  rows="1"
                  auto-grow
                  label="Note"
                  :disabled="true"
                  type="text"
                  :rules="description_rules"
                  :counter="textCounter"
                />
              </v-col>
            </v-row>
          </v-form>
          <v-data-table
            :mobile-breakpoint="0"
            :headers="shallowTicketingHeaders"
            :items="requestShallow.locations"
            :footer-props="{
              itemsPerPageAllText: $t('Table.all'),
              itemsPerPageText: $t('Table.rowsPerPage'),
              pageText: `{0}-{1} ${$t('Table.of')} {2}`
            }"
            :no-data-text="$t('Table.noData')"
          >
            <template
              v-for="h in shallowTicketingHeaders"
              v-slot:[`header.${h.value}`]="{ header }"
            >
              {{ $t(header.text) }}
            </template>
            <template
              v-slot:[`item.start_date`]="{ item }"
            >
              <span>
                {{ item.start_date ? moment(item.start_date).format('DD-MM-YYYY') : item.start_date }}
              </span>
            </template>
            <template
              v-slot:[`item.end_date`]="{ item }"
            >
              <span>
                {{ item.end_date ? moment(item.end_date).format('DD-MM-YYYY') : item.end_date }}
              </span>
            </template>
            <template
              v-slot:[`item.latitude`]="{ item }"
            >
              <span>
                {{ parseFloat(item.latitude).toFixed(2) }}
              </span>
            </template>
            <template
              v-slot:[`item.longitude`]="{ item }"
            >
              <span>
                {{ parseFloat(item.longitude).toFixed(2) }}
              </span>
            </template>
            <template v-slot:[`item.meteo_type`]="{ item }">
              <split-document-or-meteo-type :type="item.meteo_type" />
            </template>
          </v-data-table>
          <document-s3-table
            ref="documentS3TableRef"
            :request="request"
            :ticketing-path="true"
            :is-detail="true"
            :disable-form="disableForm"
            :extra-information="addFilesEnabled"
            :show-extra-informations="showExtraInformations"
            :request-type="request.request_type === 'AM' ? 'M' : request.request_type"
            @addedS3="disableModifyRequestButton = false"
          />
          <!-- <v-card-text
            v-if="showExtraInformations"
            class="d-flex justify-space-between mt-5"
          >
            <v-divider />
            <p class="mx-8 mt-n4 text-h6">
              {{ $t('TicketingPopup.requestMoreInformation') }}
            </p>
            <v-divider />
          </v-card-text> -->
          <div v-if="showExtraInformations || request.additional_notes">
            <div class="my-5">
              <v-card-title
                class="pa-0"
                style="font-size: 18px;"
              >
                <b>{{ $t('TicketingPopup.additionalNotes') }}</b>
              </v-card-title>
              <v-textarea
                v-model="additionalNotes"
                auto-grow
                class="py-0"
                :placeholder="$t('TicketingPopup.NotesPlaceholder')"
                counter="512"
                maxlength="512"
                minlength="20"
                :disabled="request.additional_notes ? true : false"
                :error-messages="getErrorMessage"
              />
            </div>
            <ticketing-priority-selection
              v-if="request.request_type === 'A'"
              :evasion-limit="requestShallow.evasion_limit"
              :location-items="requestShallow.locations"
              :is-provinciale="isProvinciale"
              @update-evasion-limit="updateEvasionLimit($event)"
              @can-be-moved="canBeMoved($event)"
            />
          </div>
        </v-card-text>
        <div class="d-flex justify-center">
          <v-card-actions>
            <v-btn
              v-if="requestShallow.request_type === 'A' && requestShallow.request_state === 'EMITTED' && checkIsAutoIsManual()"
              :color="showExtraInformations ? 'red' : '#0da344'"
              style="color:white"
              @click="showExtraInformationsToggle"
            >
              {{ showExtraInformations ? $t('TicketingPopup.requestLessInformation') : $t('TicketingPopup.requestMoreInformation') }}
            </v-btn>
            <!-- Vecchio disabled -->
            <!-- :disabled="disableForm || disableModifyRequestButton" -->
            <v-btn
              v-if="modifyButtonEnabled"
              color="#0da344"
              style="color:white"
              :disabled="checkModifyButtonIsDisabled"
              @click="modifyRequest()"
            >
              {{ showExtraInformations ? $t('TicketingPopup.sendMoreInformation') : $t('Ticketing.modify') }}
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { manageLenghtError } from '@/formRules.js';
import moment from 'moment-business-days';

export default {
  components: {
    DocumentS3Table: () => import('@/components/utils/DocumentS3Table'),
    SplitDocumentOrMeteoType: () => import('@/components/utils/SplitDocumentOrMeteoType'),
    TicketingPrioritySelection: () => import('@/components/layout/TicketingPrioritySelection')
  },
  props: {
    request: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      ticket_id: '',
      location_id: '',
      translatedState: '',
      valid: false,
      rangeMenu: false,
      overlay: false,
      isManualCoordinates: false,
      disableModifyRequestButton: true,
      requestTypes: { documentTypes: [], meteoTypes: [] },
      selectedDocumentTypes: {},
      selectedMeteoTypes: {},
      selectedDocumentTypeName: '',
      selectedDocumentTypeNameTranslated: '',
      selectedMeteoTypeName: '',
      document_type_object: {},
      meteo_type_object: {},
      requestShallow: {},
      rangeDate: [],
      shallowTicketingHeaders: [],
      description_rules: [...manageLenghtError(512)],
      additionalNotes: '',
      requestDate: '',
      evasionDate: '',
      evasionDateLimit: '',
      requestCanBeMoved: false,
      showExtraInformations: false,
      modifyButtonEnabled: false,
      addFilesEnabled: false
    };
  },
  computed: {
    ...mapState(['ticketing', 'policy', 'map']),
    requestMode() {
      switch (this.request.request_type) {
        case 'A':
          return `A - ${this.$t('TicketingRequestMode.Auto')}`;
        case 'M':
          return `M - ${this.$t('TicketingRequestMode.Manual')}`;
        case 'AM':
          return `AM - ${this.$t('TicketingRequestMode.Auto')}/${this.$t('TicketingRequestMode.Manual')}`;
        default:
          return '';
      }
    },
    requestDateFormatted() {
      return this.request.request_date ? moment(this.request.request_date).format('DD-MM-YYYY') : this.request.request_date;
    },
    evasionDateFormatted() {
      return this.request.evasion_date ? moment(this.request.evasion_date).format('DD-MM-YYYY') : this.request.evasion_date;
    },
    evasionDateLimitFormatted() {
      return this.request.evasion_limit ? moment(this.request.evasion_limit).format('DD-MM-YYYY') : this.request.evasion_limit;
    },
    getErrorMessage() {
      let errorMessage = '';

      if (this.showExtraInformations && this.additionalNotes.length < 20) {
        errorMessage = this.$t('TicketingPopup.errorNotes');
      }

      return errorMessage;
    },
    isRelazione() {
      return Object.keys(this.requestShallow.document_type).length > 0 && this.$t(this.requestShallow.document_type).toLowerCase().includes('relazione');
    },
    isProvinciale() {
      return Object.keys(this.requestShallow.document_type).length > 0 && this.$t(this.requestShallow.document_type).toLowerCase().includes('provinciale');
    },
    checkModifyButtonIsDisabled() {
      if (this.requestShallow.request_type === 'A') {
        const noteIsValid = this.additionalNotes.length >= 20;
        const evasionDateIsValid = this.evasionDateLimit.length > 0;

        return !noteIsValid || !evasionDateIsValid;
      } else {
        return this.disableModifyRequestButton;
      }
    },
    disableForm() {
      const modifyPersonalRequest = this.policy.ticketingServicePermissions.some((item) => item.permission_name == 'Modifica richieste personali');
      const modifyNodeRequest = this.policy.ticketingServicePermissions.some((item) => item.permission_name == 'Modifica richieste del nodo');
      const modifyUnderNodeRequest = this.policy.ticketingServicePermissions.some((item) => item.permission_name == 'Modifica richieste dei sotto nodi');
      const node_id = this.policy.ticketingServicePermissions[0].node_id;
      const sort_id = this.policy.ticketingServicePermissions[0].sort_id;

      if (modifyPersonalRequest) {
        if (this.request.node_id === node_id) {
          return false;
        }

        if (modifyNodeRequest && this.request.sort_id == sort_id) {
          return false;
        }

        if (modifyUnderNodeRequest && this.request.sort_id > sort_id) {
          return false;
        }

        if (modifyNodeRequest && modifyUnderNodeRequest && this.request.sort_id >= sort_id) {
          return false;
        }
      }

      if (this.showExtraInformations) {
        return false;
      }

      return true;
    },
    textCounter() {
      return this.meteo_type_object?.is_auto ? 100 : 512;
    }
  },
  watch: {
    $route() {}
  },
  async mounted() {
    this.ticketing.request_id = this.request.request_id;
    await this.getTicketingConfiguration();

    this.requestShallow = JSON.parse(JSON.stringify(this.request));

    this.translateRequestState(this.requestShallow.request_state);

    this.selectedDocumentTypeName = this.request.document_type.split(',')[0].trim();
    this.selectedDocumentTypeNameTranslated = this.$t(this.request.document_type.split(',')[1].trim());
    this.selectedMeteoTypeName = this.request.locations[0].meteo_type.split(',')[0].trim();

    this.document_type_object = this.ticketing.ticketingServiceConfiguration?.config?.find(item => item.document_type === this.selectedDocumentTypeName);
    this.meteo_type_object = this.document_type_object?.meteo_types?.find(item => item.name === this.selectedMeteoTypeName);

    // this.selectedDocumentTypes = this.request.document_type;
    // this.getTicketingRequestSelect();

    this.shallowTicketingHeaders = [...this.ticketing.headers];
    this.shallowTicketingHeaders.splice(this.shallowTicketingHeaders.findIndex(item => item.value === 'actions'), 1);

    this.map.address = '';
    this.map.latitude = '';
    this.map.longitude = '';

    this.updateComputedValues();

    if (this.requestShallow.request_type === 'A') {
      this.modifyButtonEnabled = false;
      this.addFilesEnabled = false;
    } else {
      this.modifyButtonEnabled = true;
      this.addFilesEnabled = true;
      this.additionalNotes = this.requestShallow.additional_notes;
    }
  },
  methods: {
    ...mapActions(['storeGetTicketingServiceConfiguration', 'storeGetTicketingRequest', 'editTicketingRequestAction']),
    async getTicketingConfiguration() {
      this.overlay = true;
      await this.storeGetTicketingServiceConfiguration(this.request.service_id);
      this.overlay = false;

      this.ticket_id = this.ticketing.ticketingServiceConfiguration.ticket_id;
    },
    updateComputedValues() {
      this.requestDate = this.requestDateFormatted;
      this.evasionDate = this.evasionDateFormatted;
      this.evasionDateLimit = this.evasionDateLimitFormatted;
    },
    mobileBreakpoints() {
      return this.$vuetify.breakpoint.name === 'xs';
    },
    translateRequestState(state) {
      switch (state) {
        case 'SENT':
          this.translatedState = 'Inviata';
          break;
        case 'DELETED':
          this.translatedState = 'Annullata';
          break;
        case 'TAKING CHARGE':
          this.translatedState = 'Presa in carico';
          break;
        default:
          this.translatedState = 'Evasa';
          break;
      }
    },
    canBeMoved(prevFreeSlots) {
      this.requestCanBeMoved = !this.isProvinciale && !this.isRelazione && !prevFreeSlots;
    },
    getTicketingRequestSelect() {
      this.policy.ticketingServicePermissions.forEach(item => {
        if (item.permission_name.includes('documentType')) {
          const split = item.permission_name.split(', ');
          this.requestTypes.documentTypes.push(split[0]);
        }
      });
      // this.ticketing.appUserPolicyTicketing.forEach(item => {
      //   if (item.meteo_type != '') {
      //     this.requestTypes.meteoTypes.push(`ServiceCard.${item.ticket_id}.meteoType`);
      //   }
      // });
      // this.requestTypes.documentTypes.sort((a, b) => (this.$t(a).toUpperCase() > this.$t(b).toUpperCase() ? 1 : -1));
      // this.requestTypes.meteoTypes.sort((a, b) => (this.$t(a).toUpperCase() > this.$t(b).toUpperCase() ? 1 : -1));

      // const document_type_split = this.request.document_type.split(', ');

      this.selectedDocumentTypes = this.request.document_type;
    },
    checkIsAutoIsManual() {
      if (this.meteo_type_object.is_auto && this.meteo_type_object.is_manual) {
        return true;
      } else {
        return false;
      }
    },
    async modifyRequest() {
      this.overlay = true;
      await this.$refs.documentS3TableRef.addDocument();
      const single_notification = this.policy.enabledServicesPolicy.TICKET.permissions.some(item => item.permission_name === 'Invio notifica al singolo utente');
      const group_notification = this.policy.enabledServicesPolicy.TICKET.permissions.some(item => item.permission_name === 'Invio notifica di gruppo');
      const send_notification = single_notification || group_notification;

      if (this.request.request_state !== 'DELETED') {
        const requestData = {
          app_user_ticket_id: this.request.app_user_ticket_id,
          request_id: this.request.request_id,
          request_type: this.request.request_type === 'A' ? 'AM' : this.request.request_type,
          document_type: this.requestShallow.document_type,
          username: this.request.username,
          evasion_date: this.request.evasion_date,
          evasion_limit: this.evasionDateLimit ? moment(this.evasionDateLimit, 'DD-MM-YYYY').format('YYYY-MM-DD') : '',
          request_date: this.request.request_date,
          request_state: this.request.request_state === 'EMITTED' ? 'SENT' : this.request.request_state,
          editing_user: 'app_user',
          send_email: send_notification,
          group_notification: group_notification,
          notes: this.request.notes.replace(/\\n/g, '\n').replace(/\\t/g, '\t') || '',
          additional_notes: this.additionalNotes || '',
          email: this.request.email,
          can_be_moved: this.requestCanBeMoved,
          additional_info: this.showExtraInformations
        };

        await this.editTicketingRequestAction(requestData);
      }

      await this.storeGetTicketingRequest(this.policy.ticketingServicePermissions);
      this.overlay = false;
      this.ticketing.viewTicketingDetailPopup = false;
    },
    updateEvasionLimit(value) {
      let evasion_limit_formatted = '';

      if (value) {
        evasion_limit_formatted = moment(value).format('DD-MM-YYYY');
      }

      this.evasionDateLimit = evasion_limit_formatted;
    },
    showExtraInformationsToggle() {
      this.showExtraInformations = !this.showExtraInformations;
      this.modifyButtonEnabled = !this.modifyButtonEnabled;
      this.addFilesEnabled = !this.addFilesEnabled;
    }
  }
};
</script>

<style scoped>
.v-card-scroll {
  overflow-y: scroll;
}
.ticketing-card-title {
  color: #0da344;
  background-color: #f4f3f3;
  margin-top: 1%;
  font-size: 22px;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 16px;
}
.search-location {
  width: 100%;
  border: 0;
  margin-top:1%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.40);
}
.search-location:focus {
  outline: none;
}
.ticketing-card-subtitle{
  font-size: 18px;
}
input[type="text"] {
    font-size:16px;
}
</style>
